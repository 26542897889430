<template>
  <div class="container" id="popup">
    <div class="blur" @click="hide()"></div>
    <div class="popup">
      <div v-if="headerTitle" class="toast-header d-flex justify-content-center">
        {{ headerTitle }}
      </div>
      <div class="popup-body text-center">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  props: {
    headerTitle: String,
  },
  methods: {
    hide(){
      document.getElementById('popup').classList.remove('show');
    }
  }
}
</script>

<!-- Style -->
<style scoped lang="scss" src="./Popup.scss"/>
