<template>
  <div class="launch">
    <img id="logo" class="logo" alt="logo" src="../assets/images/logos/logo.svg"/>

  </div>
</template>

<script>

import {PageName} from "@/utils/Constants.js";
import store from "@/store";

export default {
  name: "Launch",
  components: {},
  mounted() {
    const logo = document.getElementById('logo');
    //Add animation event listener, with attached function.
    logo.addEventListener('animationend', async () => {
      if (!store.state.account.isLoggedIn) {
        await this.$router.push({
          name: PageName.REGISTER
        });
      } else {
        if(store.state.progress.page===PageName.END){
          await this.$router.push({
            name: store.state.progress.page
          });
        }else{
          await this.$router.push({
            name: PageName.MODULE_OVERVIEW
          });
        }
      }
    });
  }
};
</script>

<style scoped lang="scss">
.launch {
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.logo {
  width: 240px;
  animation: loading 1.5s normal forwards ease-in-out;
}

@keyframes loading {
  0% {
    transform: scale(0.8)
  }
  50% {
    transform: scale(1)
  }
  100% {
    transform: scale(0.8)
  }
}
</style>
<style>
body{
  overflow-y: hidden;
}

@media (max-width: 575.98px) {
}
</style>

