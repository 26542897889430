<template>
  <div class="case">
    <div class="content">
      <Player :endVideo="goToReport" :video="video" :clickable="true"/>
    </div>
    <ProgressBar :class="this.redo ? 'clickable' : ''" :timestamps="timestamps"/>
    <CustomHeader ref="header" :isVideo="true" :totalCases="3" caseTitle="Opdracht"/>

    <div aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center w-100 vh-50">
      <ToastComponent id="case" :icon="toastIcon" :title="toastTitle"/>
    </div>
    <div id="clickEffect" class="clickEffect" hidden></div>
  </div>
</template>

<script>

import CustomHeader from "@/components/CustomHeader";
import Player from "@/components/video/Player";
import ProgressBar from "@/components/video/ProgressBar";
import ToastComponent from "@/components/case/Toast";
import {PageName} from "@/utils/Constants";
import {create} from "@/utils/cursor";
import store from "@/store";
import apiModule from "@/modules/APIModule";
import APIModule from "@/modules/APIModule";
import {Toast} from 'bootstrap'

export default {
  name: "Case1",
  components: {
    CustomHeader,
    Player,
    ProgressBar,
    ToastComponent
  },
  props: {
    moduleIndex: Number,
  },
  data() {
    const videos = [
      {
        src: 'https://storage.googleapis.com/lw-affectivity-samenveilig/casus-jeugdoverlast.mp4',
        thumbnail: require('@/assets/images/thumbnails/Jeugdoverlast.png')
      },
      {
        src: 'https://storage.googleapis.com/lw-affectivity-samenveilig/casus-verkeersoverlast.mp4',
        thumbnail: require('@/assets/images/thumbnails/Verkeersoverlast.png')
      },
      {
        src: 'https://storage.googleapis.com/lw-affectivity-samenveilig/casus-deurwaarder.mp4',
        thumbnail: require('@/assets/images/thumbnails/Deurwaarder.png')
      },
    ];
    return {
      currentUserCase: {},
      signals: [],
      timestamps: [],
      video: videos[store.state.progress.modules[this.moduleIndex].caseNr - 1],
      toastIcon: 'Click',
      toastTitle: 'JE KUNT VANAF NU TAGGEN!',
      redo: store.state.progress.redo
    }
  },
  async created() {
    let response = await apiModule.getUserCase(store.state.account.userId, store.state.progress.modules[this.moduleIndex].caseNr);
    if (response.data) {
      this.currentUserCase = response.data.userCase;
      this.signals = response.data.signals.map(o => o.timestamps);
      response = await apiModule.getTimeStamps(this.currentUserCase.id);
      if (response.data) {
        this.timestamps = response.data.timestamps.map(o => o.timestamp);
      }
    }
  },
  mounted() {
    // Add custom click
    create()
    const header = this.$refs.header;
    document.body.onkeyup = function (e) {
      if (e.code === "Space") {
        header.pauseOrPlay();
      }
    }
    const player = document.getElementById('player');
    if (store.state.progress.redo
      || store.state.progress.modules[this.moduleIndex].page === PageName.REPORT) {
      const progressBar = document.getElementById('progress-bar');
      progressBar.addEventListener('click', (e) => {
        player.currentTime = player.duration * (e.clientX / window.innerWidth);
      })
    }
  },
  methods: {
    showStartToast() {
      this.toastIcon = 'Click';
      this.toastTitle = 'JE KUNT VANAF NU TAGGEN!'
      let toast = document.getElementById('case');
      toast = new Toast(toast, {autohide: true, delay: 1000});
      toast.show();
    },
    showIsTaggedToast() {
      this.toastIcon = 'Checkmark';
      this.toastTitle = 'SUCCESVOL GETAGD!'
      let toast = document.getElementById('case');
      toast = new Toast(toast, {autohide: true, delay: 1000});
      toast.show();
    },
    async addSignalTimestamp() {
      const player = document.getElementById('player');
      const currentTime = Math.floor(player.currentTime);
      if (this.checkIfValidTag(currentTime)) {
        this.showIsTaggedToast();
      }
      if (!this.timestamps.includes(currentTime)) {
        this.timestamps.push(currentTime);
        await APIModule.addSignalTimestamp(this.currentUserCase.userId, this.currentUserCase.caseId, currentTime);
      }
    },
    checkIfValidTag(newTimestamp) {
      return this.signals.some((timestamps) => {
        return timestamps.some((timestamp) => {
          return newTimestamp >= timestamp[0] && newTimestamp <= timestamp[1]
        })
      });
    },
    async goToReport() {
      const moduleIndex = this.moduleIndex;
      const caseNr = store.state.progress.modules[moduleIndex].caseNr;
      const redo = store.state.progress.redo;
      this.$store.commit('report', {moduleIndex, caseNr});
      await this.$router.push({
        name: PageName.REPORT,
        query: {
          module: moduleIndex
        }
      });
      if (!redo) {
        await APIModule.updateProgress(PageName.REPORT, moduleIndex)
      }
    }
  }
}
</script>
<style lang="scss">
.toast#case {
  padding: 24px;

  .toast-header {
    margin: 0 !important;
    padding: 0 !important;
  }

  .toast-body {
    margin: 0 !important;
    padding: 0 !important;
  }
}
</style>
<style scoped lang="scss">
.vh-50 {
  height: 50vh;
}

.case {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  justify-content: end;
  align-items: center;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
  bottom: 16px;
  padding: 0 !important;
}

@media (max-width: 575.98px) {
  .content {
    height: 100vh;
    bottom: auto;
  }
}

</style>
<style>
body:has(#app > .case){
  overflow-y: hidden!important;;
}

@media (max-width: 575.98px) {
}
</style>

<style scoped lang="scss" src="../assets/scss/_cursor.scss"/>

