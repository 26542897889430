<!-- Template -->
<template>
  <div class="footer">
    <div class="footer-content">
      <PreviousButton v-if="activeIndex !== 0" :activeIndex="activeIndex" :method="goToPosition"/>
      <WalkthroughIndicator :activeIndex="activeIndex" :method="goToPosition" :amount="amount"/>
      <NextButton :isWalkthrough="true" :activeIndex="activeIndex" :method="goToPosition"/>
    </div>
  </div>
</template>


<!-- Script -->
<script lang="js">

import PreviousButton from "@/components/buttons/PreviousButton";
import WalkthroughIndicator from "@/components/walkthrough/WalkthroughIndicator";
import NextButton from "@/components/buttons/NextButton";

export default ({
  components: {
    PreviousButton,
    WalkthroughIndicator,
    NextButton,
  },
  props: {
    activeIndex: Number,
    amount: Number,
    lastItem: Boolean,
    goToPosition: Function
  }
});
</script>


<!-- Style -->
<style scoped lang="scss" src="./CustomFooter.scss"/>
