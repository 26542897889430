<template>
    <div :id="id" :class="'toast ' + (solid ? 'solid' : 'transparant')" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header d-flex justify-content-center">
        <img v-if="icon" class="icon" :src="require('@/assets/images/icons/' + icon + '.svg')" alt="Toast icon">
        <strong class="me-auto">{{title}}</strong>
      </div>
      <div class="toast-body text-center">
<!--        <div class="stroke2"></div>-->
        <slot></slot>
      </div>
    </div>
</template>

<script>
export default {
  name: "Toast",
  props: {
    id: String,
    icon: String,
    title: String,
    solid: Boolean
  }
}
</script>

<!-- Style -->
<style lang="scss" src="./Toast.scss"/>
