<template>
  <div class="register">
    <img
      class="demo-bg"
      :src="background"
      alt="background"
    >
    <CustomHeader/>
    <div id="popup_desktop" class="popup"><img src="../assets/images/icons/Checkmark.svg"
                                               style="filter: invert(99%) sepia(37%) saturate(5748%) hue-rotate(9deg) brightness(107%) contrast(111%);"
                                               class="mx-2" alt="Check icon"/><span id="popup_desktop_text"></span>
    </div>
    <div id="popup_phone" class="popup"><img src="../assets/images/icons/Checkmark.svg"
                                             style="filter: invert(99%) sepia(37%) saturate(5748%) hue-rotate(9deg) brightness(107%) contrast(111%);"
                                             class="mx-2" alt="Check icon"/><span id="popup_phone_text"></span>
    </div>
    <form @submit.prevent="handleForm">
      <div class="title">
        REGISTREER EN START
      </div>
      <div :class="'description'+(isMobile() ? ' px-4' : '')">
        <p class="mb-4">
          Welkom bij het online trainingsplatform Schakel Scherp, een initiatief van Noord-Holland Samen Veilig en het Financial Intelligence Team (<span class="font-weight-bold">FIT</span>).
        </p>
        <p>
          Schakel Scherp richt zich op het opleiden van cursisten in de wereld van misdaad.
          De trainingen op dit platform nemen je mee in verschillende criminele situaties en leren je correct en adequaat te handelen.
          Meld je hieronder aan om je training te starten.
        </p>
      </div>
      <div class="form-group px-4">
        <div class="input-group mt-5">
          <input type="text" v-model="email" placeholder="Jouw email" class="form-control" id="email">
          <CustomButton buttonType="submit">
            <b-spinner id="loader" class="mx-1" style="width: 1rem; height: 1rem;" label="Loading.." hidden></b-spinner>
            <span id="button-text">VERSTUUR LINK</span>
          </CustomButton>
        </div>
      </div>
      <p class="text-danger" id="errorMessage" hidden/>
    </form>
    <div aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center w-100 vh-50">
      <div class="blur" id="blur" hidden></div>
      <ToastComponent id="error" title="Foutmelding" :solid="true">
        <p class="mb-3">Je bevindt je in een afgeschermde omgeving, gebruik de QR-code om met je eigen device de training te starten.</p>
        <qr-code class="qr" :size="120" :text="qrLink"></qr-code>
      </ToastComponent>
    </div>
  </div>
</template>

<script>

import CustomButton from "@/components/buttons/CustomButton.vue";
import apiModule from "@/modules/APIModule.js";
import {ErrorMessage, PageName} from "@/utils/Constants";
import ConfigModule from "@/modules/ConfigModule";
import CustomHeader from "@/components/CustomHeader";
import ToastComponent from "@/components/case/Toast";
import {Toast} from 'bootstrap';

export default {
  name: "Register",
  components: {
    CustomHeader,
    CustomButton,
    ToastComponent
  },
  props: {
    login: Boolean
  },
  data: function () {
    const data = ConfigModule.getPageByName(PageName.REGISTER);
    const description = String(data.description);
    return {
      email: "",
      description,
      background: require('@/assets/images/backgrounds/NH Samen Veilig - Case - Verkeersoverlast - V3.00_00_31_20.Still006.png'),
      qrLink: window.location.host + '/register'
    }
  },
  mounted() {
    if (this.login) {
      const popupDesktop = document.getElementById('popup_desktop');
      const popupPhone = document.getElementById('popup_phone');
      this.showPopup(popupDesktop, popupPhone, 'LOGIN LINK NIET GELDIG', 'LINK ONGELDIG', true);
    }
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    async handleForm() {
      if(!document.getElementById('loader').hidden)
        return;
      const errorMessage = document.getElementById('errorMessage');
      const popupDesktop = document.getElementById('popup_desktop');
      const popupPhone = document.getElementById('popup_phone');
      errorMessage.hidden = true;
      this.hidePopup(popupDesktop, popupPhone);
      if (!this.email) {
        errorMessage.hidden = false;
        errorMessage.innerText = "Email is verplicht";
        return;
      }
      document.getElementById('loader').hidden = false;
      try {
        const response = await apiModule.createEmailUser(this.email);
        document.getElementById('loader').hidden = true;
        if (!response.data.success) {
          errorMessage.hidden = false;
          errorMessage.innerText = ErrorMessage[response.data[response.data.length - 1].msg];
        } else {
          this.showPopup(popupDesktop, popupPhone, 'EMAIL IS SUCCESVOL VERSTUURD', 'EMAIL VERZONDEN', false);
        }
      } catch (e) {
        document.getElementById('loader').hidden = true;
        document.getElementById('blur').hidden = false;
        this.showQrCode();
      }
      window.dataLayer?.push({event: 'sign_up', });
    },
    hidePopup(popupDesktop, popupPhone) {
      if (popupDesktop.classList.contains('show')) {
        popupDesktop.classList.remove('show', 'clickable');
      }
      if (popupPhone.classList.contains('show')) {
        popupPhone.classList.remove('show', 'clickable');
      }
    },
    showPopup(popupDesktop, popupPhone, desktopText, phoneText, hideIcon) {
      if (hideIcon) {
        popupDesktop.children[0].hidden = true;
        popupPhone.children[0].hidden = true;
      } else {
        popupDesktop.children[0].hidden = false;
        popupPhone.children[0].hidden = false;
      }
      popupDesktop.classList.add('show', 'clickable');
      popupPhone.classList.add('show', 'clickable');
      popupDesktop.children[1].textContent = desktopText;
      popupPhone.children[1].textContent = phoneText;
      popupDesktop.addEventListener('click', (e) => {
        e.preventDefault();
        popupDesktop.classList.remove('show', 'show', 'clickable');
      })
      popupPhone.addEventListener('click', (e) => {
        e.preventDefault();
        popupPhone.classList.remove('show', 'clickable');
      })
    },
    showQrCode() {
      let toast = document.getElementById('error');
      toast = new Toast(toast, {autohide: false});
      toast.show();
    }
  }
};
</script>

<style scoped lang="scss">

h1{
  font-family: "Source Sans Pro", sans-serif;
  color: #F3FF00;
}

p {
  margin: 0 0 0.2em 0;
}

h4 {
  margin: 0 0 0.5em 0;
}

.title {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: white;
}

.register {
  max-height: 100vh;
  display: flex;
  justify-content: center;
}

form {
  display: flex;
  flex-flow: column;
  align-items: center;
  color: #FFFFFF;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

form {
  .form-group {
    .input-group {
      margin-left: auto;
      margin-right: auto;

      .button {
        background-color: #F3FF00;
        width: 177px !important;
        height: 48px;
        FONT-WEIGHT: 700;
        font-size: 16px;
        line-height: 24px;
        clip-path: none !important;
        pointer-events: all;
      }
    }
  }
}

input {
  height: 48px;
  border-radius: 0;
  width: 410px !important;
  pointer-events: all;
}

form > img {
  width: 100px;
  margin-bottom: 1em;
}

a {
  color: #F3FF00;
  pointer-events: all;
}

.description {
  width: 587px !important;
  margin-top: 2em;
}

.popup {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  height: 56px;
  top: 0;
  background: rgba(243, 255, 0, 0.4);
  color: #F3FF00;
  backdrop-filter: blur(12px);
  clip-path: polygon(0% 0%, 92.5% 0%, 100% 100%, 7.5% 100%);
  opacity: 0;
  transition: opacity ease-in-out 0.5s;
}

.popup.show {
  opacity: 1 !important;
}

#popup_desktop {
  visibility: visible;
}

#popup_phone {
  visibility: hidden;
}

.blur {
  position: absolute !important;
  height: 100vh !important;
  width: 100% !important;
  background-color: #212121;
  opacity: 60%;
}

.qr{
  display: flex;
  justify-content: center !important;
  margin-top: 1rem !important;
  margin-bottom: 1.5rem !important;
}

@media (max-width: 575.98px) {
  .register{
    height: 100vh;
    max-height: none;
  }

  input {
    width: 1% !important;
  }

  form {
    top: 55%;
  }

  .description {
    margin-top: 1.5em;
    width: 100% !important;
  }

  .mt-5 {
    margin-top: 2rem !important;
  }

  #popup_desktop {
    visibility: hidden;
  }

  #popup_phone {
    visibility: visible;
  }
}

@media (min-height: 300px) and (max-height: 420px) {
  form {
    top: 52.5%;

    img {
      width: 10%;
    }
  }

  .title {
    font-size: 30px;
  }

  .description {
    font-size: 15px;
    margin-top: 0.5em;
  }

  .mb-4 {
    margin-bottom: 0.5rem !important;
  }

  .mt-5 {
    margin-top: 1rem !important;
  }

  .popup {
    font-size: 13px;
  }
}
</style>
<style>
body:has(#app > .register){
  overflow-y: hidden;
}

@media (max-width: 575.98px) {
}
</style>


