<template>
  <div class="case-index">
    <div id="web"><span class="font-weight-bold">Casus {{caseNr}}/</span>{{totalCases}} | {{ title }}</div>
    <div id="phone"><span class="font-weight-bold">Casus {{caseNr}}/</span>{{totalCases}} <br> {{ title }}</div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "CaseIndex",
  props: {
    totalCases: Number,
    title: String
  },
  data(){
    let params = new URLSearchParams(window.location.search);
    return  {
      caseNr : store.state.progress.modules[params.get('module')].caseNr
    }
  }
}
</script>

<!-- Style -->
<style lang="scss" src="./CaseIndex.scss"/>
