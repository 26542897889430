<template>
  <div class="end d-flex justify-content-center align-items-center vh-100">
    <CustomHeader/>
    <div :class="'content id' + this.moduleIndex">
      <img class="walkthrough-icon" :src="require('@/assets/' + content.icon + '')" alt="icon"/>
      <h1 class="title">
        {{ content.title }}
      </h1>
      <p class="description" v-html="content.description"/>
      <div class="button-container">
        <SecondaryButton :method="goToOverview">
          TERUG NAAR OVERZICHT
        </SecondaryButton>
        <CustomButton v-if="this.moduleIndex === 0" :method="goToNextModule" id="button">
          START VOLGENDE MODULE
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/buttons/CustomButton";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import CustomHeader from "@/components/CustomHeader";
import ConfigModule from "@/modules/ConfigModule";
import {PageName} from "@/utils/Constants";

export default {
  name: "End",
  components: {
    CustomHeader,
    CustomButton,
    SecondaryButton
  },
  props: {
    moduleIndex: Number,
  },
  data() {
    const content = ConfigModule.getPageByName(PageName.END).modules[this.moduleIndex];
    return {
      content
    }
  },
  methods: {
    async goToOverview() {
      await this.$router.push({
        name: PageName.MODULE_OVERVIEW
      });
    },
    async goToNextModule(){
      await this.$router.push({
        name: PageName.CASE_OVERVIEW,
        query: {
          module: this.moduleIndex + 1
        }
      });
    }
  }
}
</script>

<style scoped>
body {
  max-height: 100% !important;
}

.content {
  width: 75%;
  align-items: center;
  display: flex;
  flex-flow: column;
}

.title {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: white;
  margin: 0;
}

.stroke2 {
  background: white;
  margin-top: 16px;
  max-width: 452px;
}

.description {
  font-size: 20px;
  line-height: 29px;
  color: white;
  margin-top: 24px;
  margin-bottom: 0;
}

.walkthrough-icon {
  width: 148px;
  height: 120px;
  margin-bottom: 40px;
}

.button-container {
  display: flex;
  gap: 16px;
  margin-top: 52px;
}

@media (max-width: 575.98px) {
  .walkthrough-icon{
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
  }
  .content.id0 {
    margin-top: 10em !important;
  }
  .content.id1 {
    margin-top: 4em !important;
  }
  .content {
    padding: 0;
  }
  .button-container{
    margin-top: 30px;
    margin-bottom: 30px;
    flex-flow: column;
  }
}

@media (min-height: 300px) and (max-height: 420px) {
  .walkthrough-icon{
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
  }
  .content {
    margin-top: 10em !important;
    padding: 0;
  }
  .button-container{
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .title {
    font-size: 35px;
  }
}
</style>
<style>
body:has(#app > .end){
  overflow-y: hidden!important;;
}

@media (max-width: 575.98px) {
  body:has(#app > .end){
    overflow-y: auto!important;;
  }
}

@media (min-height: 300px) and (max-height: 420px) {
  body:has(#app > .end) {
    overflow-y: auto !important;
  }
}
</style>

