<!-- Template -->
<template>
  <div class="walkthrough-indicator">
    <div class="walkthrough-indicator-container">
      <div v-for="i in amount" class="walkthrough-indicator-bullet clickable" :class="getBulletState(i)" @click="method(i-1)"/>
    </div>
  </div>
</template>

<!-- Script -->
<script lang="js">

export default ({
  props: {
    amount: Number,
    activeIndex: Number,
    method: Function
  },
  methods: {
    getBulletState(index) {
      const activeIndex = (this.activeIndex || 0) + 1;
      return index < activeIndex ? "passed" : index === activeIndex ? "active" : "passed";
    }
  }
});
</script>


<!-- Style -->
<style scoped lang="scss" src="./WalkthroughIndicator.scss"/>
