<!-- Template -->
<template>
  <div class="header">
    <img @click="goToHome" class="logo clickable" src="@/assets/images/logos/logo_small.svg" alt="logo">
    <CaseIndex v-if="totalCases" :title="caseTitle" :totalCases="totalCases"/>
    <div v-if="isVideo" @click="turnSoundOnOrOff" id="sound" class="icon-rounded clickable">
      <svg id="volume-up" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-volume-up-fill" viewBox="0 0 16 16">
        <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
        <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
        <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/>
      </svg>
      <svg id="volume-mute" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-volume-mute-fill" viewBox="0 0 16 16" style="display: none">
        <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/>
      </svg>
    </div>
    <div v-if="isVideo" @click="pauseOrPlay" id="play" class="mx-2 icon-rounded clickable">
      <img :src="icons.play" alt="sound">
    </div>
    <div></div>
    <a v-if="showMenu" class="contact-btn" @click="goToContact">CONTACT</a>
  </div>
</template>


<!-- Script -->
<script lang="js">

import CaseIndex from "@/components/case/CaseIndex";
import Menu from "@/components/Menu";
import {PageName} from "@/utils/Constants";
import store from "@/store";

export default ({
  name: "CustomHeader",
  props: {
    totalCases: Number,
    caseTitle: String,
    isVideo: Boolean,
    showMenu: Boolean,
  },
  components: {
    CaseIndex,
    Menu
  },
  data() {
    let params = new URLSearchParams(window.location.search);
    return {
      icons: {
        pause: require('@/assets/images/icons/Pause.svg'),
        play: require('@/assets/images/icons/Play.svg'),
        menu: require('@/assets/images/icons/Menu.svg'),
        home: require('@/assets/images/icons/Home.svg'),
      },
      firstClick: true,
      moduleIndex: params.get('module')
    }
  },
  mounted() {
    if (this.showMenu) {
      const app = document.getElementById('app');
      document.getElementById('app').setAttribute('closeMenu', 'false')
      app.addEventListener('click', () => {
        const menu = document.getElementById('circularMenu');
        if (menu && menu.classList.contains('active') && app.getAttribute('closeMenu') === 'true') {
          menu.classList.toggle('active');
          document.getElementById('app').setAttribute('closeMenu', 'false')
        }
      });
    }
    if (this.isVideo) {
      // Add click event listener
      document.getElementsByClassName('player')[0].addEventListener('click', async (e) => {
        e.preventDefault();
        if (this.firstClick) {
          if(document.getElementById('playButton')){
            document.getElementById('playButton').hidden = true;
          }
          this.firstClick = false;
          const playBtn = document.getElementById('play');
          playBtn.id = 'pause';
          playBtn.children[0].src = this.icons.pause;
          if (!store.state.progress.redo
            && store.state.progress.modules[this.moduleIndex].page === PageName.CASE1) {
            this.$parent.showStartToast();
          }
        } else if (!store.state.progress.redo
          && store.state.progress.modules[this.moduleIndex].page === PageName.CASE1) {
          await this.$parent.addSignalTimestamp();
        }
      });
      document.getElementById('player').addEventListener('play', async () => {
        const pauseBtn = document.getElementById('pause');
        if(!pauseBtn){
          this.playVideo(document.getElementById('player'),  document.getElementById('play'));
        }
      });
    }
  },
  methods: {
    async goToHome() {
      if (this.$router.currentRoute.name !== PageName.MODULE_OVERVIEW) {
        await this.$router.push({
          name: PageName.MODULE_OVERVIEW
        });
      }
    },
    async goToContact(){
      await this.$router.push({
        name: PageName.CONTACT
      });
      window.dataLayer?.push({event: 'contact', });
    },
    turnSoundOnOrOff() {
      const player = document.getElementById('player');
      const iconVolumeUp = document.getElementById('volume-up');
      const iconVolumeMute = document.getElementById('volume-mute');
      if(player.muted){
        iconVolumeUp.style.display = "block";
        iconVolumeMute.style.display = "none";
      }else{
        iconVolumeUp.style.display = "none";
        iconVolumeMute.style.display = "block";
      }
      player.muted = !player.muted;
    },
    pauseOrPlay() {
      const playBtn = document.getElementById('play');
      const player = document.getElementById('player');
      if (player) {
        if (playBtn) {
          this.playVideo(player, playBtn);
        } else {
          this.pauseVideo(player, playBtn);
        }
      }
    },
    pauseVideo(player) {
      const pauseBtn = document.getElementById('pause');
      player.pause();
      pauseBtn.id = 'play';
      pauseBtn.children[0].src = this.icons.play;
    },
    playVideo(player, playBtn) {
      if (this.firstClick) {
        if(document.getElementById('playButton')){
          document.getElementById('playButton').hidden = true;
        }
        this.firstClick = false;
        if (!store.state.progress.redo
          && store.state.progress.modules[this.moduleIndex].page === PageName.CASE1) {
          this.$parent.showStartToast();
        }
      }
      player.play();
      playBtn.id = 'pause';
      playBtn.children[0].src = this.icons.pause;
    }
  }
});
</script>


<!-- Style -->
<style lang="scss" src="./CustomHeader.scss"/>
