<template>
  <a v-if="isWalkthrough" class="next-button" @click="method(activeIndex+1)"><img src="@/assets/images/buttons/round/primary_round.png" alt="next button"></a>
  <a v-else class="next-button" @click="method"><img src="@/assets/images/buttons/round/primary_round.png" alt="next button"></a>
</template>

<script>
export default {
  name: "NextButton",
  props: {
    isWalkthrough: Boolean,
    activeIndex: Number,
    method: Function
  },
}
</script>

<style scoped>
.next-button{
  pointer-events: all;
  float: right;
  z-index:5;
}
</style>
