<template>
  <div class="report">
    <CustomHeader :totalCases="3" caseTitle="Reflectie formulier"/>
    <div class="container">
      <div class="content">
        <div class="title">
          {{ content.title }}
        </div>
        <div class="description">
          {{ content.description }}
        </div>
        <ReportContent :summary="summary" :signals="signals"/>
      </div>
    </div>

    <div class="footer">
      <PreviousButton :method="goToCaseVideo"/>
      <NextButton :method="goToReflectionVideo"/>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import CustomHeader from "@/components/CustomHeader";
import ReportContent from "@/components/report/ReportContent";
import NextButton from "@/components/buttons/NextButton";
import apiModule from "@/modules/APIModule";
import APIModule from "@/modules/APIModule";
import ConfigModule from "@/modules/ConfigModule";
import {PageName} from "@/utils/Constants";
import PreviousButton from "@/components/buttons/PreviousButton";

export default {
  name: "Report",
  components: {
    PreviousButton,
    CustomHeader,
    ReportContent,
    NextButton
  },
  props: {
    moduleIndex: Number,
  },
  data() {
    const content = ConfigModule.getPageByName(PageName.REPORT);
    return {
      content,
      currentUserCase: {},
      summary: "",
      signals: [],
    }
  },
  async created() {
    let response = await apiModule.getUserCase(store.state.account.userId, store.state.progress.modules[this.moduleIndex].caseNr);
    if (response.data) {
      this.currentUserCase = response.data.userCase;
      this.summary = response.data.case.summary;
      this.signals = response.data.signals;
      const array = [];
      response = await apiModule.getTimeStamps(this.currentUserCase.id);
      if (response.data) {
        const timestamps = response.data.timestamps.filter(o => o.valid === true).map(o => o.timestamp);
        this.signals.forEach((signal) => {
          signal.valid = signal.timestamps.some((timestamp) => {
            return timestamps.filter(validTimestamp => validTimestamp >= timestamp[0] && validTimestamp <= timestamp[1]).length > 0;
          })
          array.push(signal);
        })
        this.signals = array;
      }
    }
  },
  methods: {
    async goToCaseVideo() {
      await this.$router.push({
        name: PageName.CASE1,
        query: {
          module: this.moduleIndex
        }
      });
    },
    async goToReflectionVideo() {
      const moduleIndex = this.moduleIndex;
      const caseNr = store.state.progress.modules[moduleIndex].caseNr;
      const redo = store.state.progress.redo;
      this.$store.commit('reflection', {moduleIndex, caseNr});
      await this.$router.push({
        name: PageName.REFLECTION,
        query: {
          module: moduleIndex
        }
      });
      if (!redo) {
        await APIModule.updateProgress(PageName.REFLECTION, moduleIndex)
      }
    }
  }
}
</script>

<style scoped>
.report {
  height: 100vh;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 6em;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  color: white;
}

.content {
  width: 90%;
  color: white
}

.title {
  font-weight: 700;
  font-size: 40px;
  text-align: center;
}

.description {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 48px;
}

@media (max-width: 575.98px) {
  .container {
    margin-top: 4em;
  }

  .content {
    width: 100%;
  }

  .title {
    font-size: 30px;
    line-height: 34px;
  }

  .description {
    font-size: 16px;
    line-height: 20px;
  }

  .footer {
    padding: 28px;
  }
}

@media (min-height: 300px) and (max-height: 420px) {
  .footer {
    padding-bottom: 30px;
    padding-right: 40px;
  }

  .container {
    margin-top: 4em;
  }
}
</style>
<style>
body:has(#app > .report){
  overflow-y: auto;
}

@media (max-width: 575.98px) {
}
</style>
