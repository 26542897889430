<template>
  <div id="circularMenu" class="circular-menu circular-menu-right">

    <a class="floating-btn clickable" @click="openMenu">
      <img :src="icons.menu" alt="Menu toggle">
    </a>

    <menu class="items-wrapper">
      <a class="menu-item clickable" @click="goToInfoPage" title="Meer informatie"><font-awesome-icon :icon="['fa', 'info']"/></a>
      <a class="menu-item clickable" @click="goToContactPage" title="Contact"><font-awesome-icon :icon="['fa', 'address-book']"/></a>
    </menu>
  </div>
</template>

<script>
import {PageName} from "@/utils/Constants";

export default {
  name: "Menu",
  data() {
    return {
      icons: {
        menu: require('@/assets/images/icons/Menu.svg')
      }
    }
  },
  methods: {
    openMenu(){
      const menu = document.getElementById('circularMenu');
      menu.classList.toggle('active');
      if(menu.classList.contains('active')){
        setTimeout(function() {
          document.getElementById('app').setAttribute('closeMenu', 'true')
        }, 50);
      }else{
        document.getElementById('app').setAttribute('closeMenu', 'false')
      }
    },
    async goToWalkthroughPage() {
      await this.$router.push({
        name: PageName.WALKTHROUGH
      });
    },
    async goToInfoPage() {
      await this.$router.push({
        name: PageName.INFO
      });
    },
    async goToContactPage() {
      await this.$router.push({
        name: PageName.CONTACT
      });
    }
  }
}
</script>

<!-- Style -->
<style lang="scss" src="./Menu.scss"/>
