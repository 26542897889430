import { render, staticRenderFns } from "./Case2.vue?vue&type=template&id=560a2a7b&scoped=true&"
import script from "./Case2.vue?vue&type=script&lang=js&"
export * from "./Case2.vue?vue&type=script&lang=js&"
import style0 from "./Case2.vue?vue&type=style&index=0&id=560a2a7b&scoped=true&lang=css&"
import style1 from "./Case2.vue?vue&type=style&index=1&lang=css&"
import style2 from "../assets/scss/_cursor.scss?vue&type=style&index=2&id=560a2a7b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "560a2a7b",
  null
  
)

export default component.exports