<template>
  <div class="reflection">
    <div class="content">
      <Player :endVideo="goToNextCase" :video="video"/>
    </div>
    <ProgressBar class="clickable"/>
    <CustomHeader ref="header" :isVideo="true" :totalCases="3" caseTitle="Reflectie video"/>
  </div>
</template>

<script>
import store from "@/store";
import {PageName} from "@/utils/Constants";
import CustomHeader from "@/components/CustomHeader";
import Player from "@/components/video/Player";
import ProgressBar from "@/components/video/ProgressBar";
import APIModule from "@/modules/APIModule";

export default {
  name: "Reflection",
  components: {
    CustomHeader,
    Player,
    ProgressBar
  },
  props: {
    moduleIndex: Number,
  },
  data() {
    const videos = [
      {
        src: 'https://storage.googleapis.com/lw-affectivity-samenveilig/kantoorsessie-jeugdoverlast.mp4',
        thumbnail: ''
      },
      {
        src: 'https://storage.googleapis.com/lw-affectivity-samenveilig/kantoorsessie-verkeersoverlast.mp4',
        thumbnail: ''
      },
      {
        src: 'https://storage.googleapis.com/lw-affectivity-samenveilig/kantoorsessie-deurwaarder.mp4',
        thumbnail: ''
      },
    ]
    return {
      video: videos[store.state.progress.modules[this.moduleIndex].caseNr - 1],
    }
  },
  mounted() {
    const progressBar = document.getElementById('progress-bar');
    progressBar.addEventListener('click', (e) => {
      const player = document.getElementById('player');
      player.currentTime = player.duration * (e.clientX / window.innerWidth);
    })
    const player = document.getElementById('player');
    const header = this.$refs.header;
    document.body.onkeyup = function (e) {
      if (e.code === "Space") {
        const playBtn = document.getElementById('play');
        if (playBtn) {
          header.playVideo(player, playBtn);
        } else {
          header.pauseVideo(player, playBtn);
        }
      }
    }
  },
  methods: {
    async goToNextCase() {
      const moduleIndex = this.moduleIndex;
      let caseNr = store.state.progress.modules[moduleIndex].caseNr;
      const redo = store.state.progress.redo;
      if(!redo){
        caseNr = caseNr + 1;
        if (caseNr > 3) {
          this.$store.commit('end', moduleIndex);
          await this.$router.push({
            name: PageName.END,
            query: {
              module: moduleIndex
            }
          });
          await APIModule.updateProgress(PageName.END, moduleIndex)
          return;
        } else {
          await this.$store.commit('case', {moduleIndex, caseNr});
          await APIModule.updateProgress(PageName.CASE1, moduleIndex, caseNr)
        }
      }
      await this.$router.push({
        name: PageName.CASE_OVERVIEW,
        query: {
          module: moduleIndex
        }
      });
    }
  }
}
</script>

<style scoped>
.reflection {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.content{
  display: flex;
  justify-content: end;
  align-items: center;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
  bottom: 16px;
  padding: 0!important;
}

@media (max-width: 575.98px) {
  .content {
    height: 100vh;
    bottom: auto;
  }
}
</style>
<style>
body:has(#app > .reflection){
  overflow-y: hidden;
}

@media (max-width: 575.98px) {
}
</style>


