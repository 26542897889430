<template>
  <a v-if="link" class="button btn" :href="link">
    <slot/>
  </a>
  <button v-else-if="method" class="button" @click="method" :type="buttonType">
    <slot/>
  </button>
  <button v-else class="button" :type="buttonType">
    <slot/>
  </button>
</template>

<script>
export default {
  name: "CustomButton",
  props: {
    buttonType: String,
    method: Function,
    link: String
  }
}
</script>

<!-- Style -->
<style scoped lang="scss" src="./CustomButton.scss"/>
