<template>
  <div :class="this.video.disabled ? 'case-display row disabled' : (this.video.active ? 'case-display row active' : 'case-display row completed')" @click="goToPage()">
      <a v-if="!video.disabled" class="thumbnail clickable" >
        <img v-if="!this.video.disabled && !this.video.active" class="checkmark" src="../../assets/images/indicators/completed_task.png" alt="completed">
        <img class="border-hover" :src="video.thumbnail" alt="case thumbnail">
      </a>
      <a v-else class="thumbnail disabled clickable">
        <img class="border-hover" :src="video.thumbnail" alt="case thumbnail">
      </a>
      <div class="info col">
        <h2 class="title my-2">
      <img src="../../assets/images/icons/lock.png" v-if="video.disabled" alt="lock" class="lock">
          <!-- <font-awesome-icon v-if="video.disabled" :icon="['fa', 'lock']"/> -->
          {{ (!video.disabled ? nr+1 + '. ' : '') + video.title }}
        </h2>
        <p class="time mb-2">
          <font-awesome-icon class="small-icon" v-if="video.duration" icon="fa-regular fa-clock"/><span v-if="video.duration" class="mx-2">{{ video.duration }} minuten</span>
          <font-awesome-icon class="small-icon" v-if="video.parts" :icon="['fa', 'bars']"/><span v-if="video.parts" class="ml-2">{{ video.parts }} onderdelen</span>
        </p>
        <p class="description mb-2">
          {{
            video.description
          }}
        </p>
      </div>
      <div class="line" v-if="total !== nr"></div>
  </div>
</template>

<script>
export default {
  name: "CaseDisplay",
  props: {
    nr: Number,
    total: Number,
    video: Object,
    goToPage: Function
  },
  data() {
    return {
      icons: {
        check: require('@/assets/images/icons/Checkmark.svg')
      }
    }
  }
}
</script>

<!-- Style -->
<style lang="scss" src="./CaseDisplay.scss"/>

