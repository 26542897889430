import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Launch from '../views/Launch.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Walkthrough from '../views/Walkthrough.vue'
import Case1 from '../views/Case1.vue'
import Case2 from '../views/Case2.vue'
import {PageName} from "@/utils/Constants";
import Report from "@/views/Report";
import End from "@/views/End";
import Reflection from "@/views/Reflection";
import CaseOverview from "@/views/CaseOverview";
import ModuleOverview from "@/views/ModuleOverview";
import Contact from "@/views/Contact";

Vue.use(VueRouter)

const whiteList = [
  PageName.CASE_OVERVIEW,
  PageName.MODULE_OVERVIEW,
  PageName.INTRODUCTION,
  PageName.WALKTHROUGH,
  PageName.CONTACT,
  PageName.INFO
]

const routes = [
  {
    path: '/',
    name: 'Launch',
    component: Launch
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/walkthrough',
    name: 'Walkthrough',
    component: Walkthrough,
    props: route => ({ moduleIndex: parseInt(route.query.module) }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/modules',
    name: 'ModuleOverview',
    component: ModuleOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/overview',
    name: 'CaseOverview',
    component: CaseOverview,
    props: route => ({ moduleIndex: parseInt(route.query.module) }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/case1',
    name: 'Case1',
    component: Case1,
    props: route => ({ moduleIndex: parseInt(route.query.module) }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/case2',
    name: 'Case2',
    component: Case2,
    props: route => ({ moduleIndex: parseInt(route.query.module) }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
    props: route => ({ moduleIndex: parseInt(route.query.module) }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reflection',
    name: 'Reflection',
    component: Reflection,
    props: route => ({ moduleIndex: parseInt(route.query.module) }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/end',
    name: 'End',
    component: End,
    props: route => ({ moduleIndex: parseInt(route.query.module) }),
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Check if route exists
  if (Object.values(PageName).indexOf(to.name) === -1) {
    next({name: PageName.LAUNCH})
  }
  // Check if logged in
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to register page.
    if (!store.state.account.isLoggedIn) {
      next({name: PageName.REGISTER})
    } else {
      const moduleIndex = to.query.module !== undefined ? to.query.module : to.query.moduleIndex;
      if (moduleIndex !== undefined && to.name === store.state.progress.modules[moduleIndex].page) {
        next()
      } else if (whiteList.includes(to.name) || (moduleIndex !== undefined &&
        (store.state.progress.redo
        || (store.state.progress.modules[to.params.module].page === PageName.REPORT
          && to.name === PageName.CASE1)))) {
        next()
      } else {
        next({name: PageName.MODULE_OVERVIEW})
      }
    }
  } else {
    next()
  }
})

export default router
