<template>
  <div class="case">
    <div class="content">
      <Player :endVideo="goToCaseOverview" :video="video"/>
      <option :class="loop ? 'active': ''" id="option2" @click="option2"></option>
      <option :class="loop ? 'active': ''" id="option1" @click="option1"></option>
    </div>
    <ProgressBar/>
    <CustomHeader ref="header" :isVideo="true" :totalCases="1" caseTitle="Opdracht"/>
  </div>
</template>

<script>

import CustomHeader from "@/components/CustomHeader";
import Player from "@/components/video/Player";
import ProgressBar from "@/components/video/ProgressBar";
import {PageName} from "@/utils/Constants";
import store from "@/store";
import APIModule from "@/modules/APIModule";

export default {
  name: "Case",
  components: {
    CustomHeader,
    Player,
    ProgressBar,
  },
  props: {
    moduleIndex: Number,
  },
  data() {
    const videos = [
      {
        src: 'https://storage.googleapis.com/lw-affectivity-samenveilig/betekenisvol_afpakken.mp4',
        thumbnail: require('@/assets/images/thumbnails/betekenisvol_afpakken.jpg'),
        tree: [
          {
            begin: 0,
            end: 157.12,
            question: {
              loop: {
                begin: 157.12,
                end: 160.12,
              },
              option1: {
                answer: 'Niet afpakken',
                to: 1,
              },
              option2: {
                answer: 'Wel afpakken',
                to: 3
              }
            }
          },
          {
            begin: 160.12,
            end: 483.05,
            question: {
              loop: {
                begin: 483.05,
                end: 486.05,
              },
              option1: {
                answer: 'Niet klaar',
                to: 'end',
                begin: 795.08,
                end: 1029.04,
              },
              option2: {
                answer: 'Wel klaar',
                to: 2,
              }
            }
          },
          {
            begin: 1035.11,
            end: 1219.02,
            question: {
              loop: {
                begin: 1219.02,
                end: 1222.02,
              },
              option1: {
                answer: 'Nee',
                to: 'end',
                begin: 1222.06,
                end: 1310.15,
              },
              option2: {
                answer: 'Ja',
                to: 'end',
                begin: 1310.17,
                end: 1364.05,
              }
            }
          },
          {
            begin: 486.05,
            end: 792.05,
            question: {
              loop: {
                begin: 792.05,
                end: 795.05,
              },
              option1: {
                answer: 'Nee',
                to: 'end',
                begin: 795.08,
                end: 1029.04,
              },
              option2: {
                answer: 'Ja',
                to: 4
              }
            }
          },
          {
            begin: 1035.11,
            end: 1219.02,
            question: {
              loop: {
                begin: 1219.02,
                end: 1222.02,
              },
              option1: {
                answer: 'Nee',
                to: 'end',
                begin: 1222.06,
                end: 1310.15,
              },
              option2: {
                answer: 'Ja',
                to: 'end',
                begin: 1310.17,
                end: 1364.05,
              }
            }
          }
        ]
      }
    ];
    const startIndex = 0;
    const videoIndex = store.state.progress.modules[this.moduleIndex].caseNr - 1;
    return {
      video: videos[videoIndex],
      numberOfVideos: videos.length,
      currentIndex: startIndex,
      loop: false,
      end: {
        option: 1,
        isEnd: false
      },
      redo: store.state.progress.redo
    }
  },
  mounted() {
    const header = this.$refs.header;
    document.body.onkeyup = function (e) {
      if (e.code === "Space") {
        header.pauseOrPlay();
      }
    }
    const player = document.getElementById('player');
    player.addEventListener('VideoTimelapse', this.check);
    if (Boolean(process.env.VUE_APP_DEBUG) === true) {
      const progressBar = document.getElementById('progress-bar');
      progressBar.addEventListener('click', (e) => {
        player.currentTime = player.duration * (e.clientX / window.innerWidth);
      })
    }
  },
  methods: {
    check(ev) {
      const v = ev.target;
      const currentSection = this.video.tree[this.currentIndex];

      if (this.end.isEnd && currentSection.question['option' + this.end.option].begin > v.currentTime) {
        const player = document.getElementById('player');
        player.currentTime = currentSection.question['option' + this.end.option].begin;
        return;
      }
      if (this.end.isEnd && currentSection.question['option' + this.end.option].end <= v.currentTime) {
        this.goToCaseOverview();
        return;
      }
      if (this.end.isEnd)
        return;
      if (currentSection.begin > v.currentTime) {
        const player = document.getElementById('player');
        player.currentTime = currentSection.begin;
        return;
      }
      if (this.loop && currentSection.question.loop.end <= v.currentTime) {
        const player = document.getElementById('player');
        player.currentTime = currentSection.question.loop.begin;
        return;
      }
      if (currentSection.end > v.currentTime)
        return;
      this.loop = true;
    },
    option1() {
      const currentSection = this.video.tree[this.currentIndex];
      if (currentSection.question.option1.to === 'end')
        this.end = {
          option: 1,
          isEnd: true
        };
      else
        this.currentIndex = currentSection.question.option1.to;
      this.loop = false;

    },
    option2() {
      const currentSection = this.video.tree[this.currentIndex];
      if (currentSection.question.option2.to === 'end')
        this.end = {
          option: 2,
          isEnd: true
        };
      else
        this.currentIndex = currentSection.question.option2.to;
      this.loop = false;
    },
    async goToCaseOverview() {
      const moduleIndex = this.moduleIndex;
      const redo = store.state.progress.redo;
      let caseNr = store.state.progress.modules[moduleIndex].caseNr;
      if (!redo) {
        caseNr = caseNr + 1;
        if (caseNr > this.numberOfVideos) {
          this.$store.commit('end', moduleIndex);
          await this.$router.push({
            name: PageName.END,
            query: {
              module: moduleIndex
            }
          }).catch(()=>{});
          await APIModule.updateProgress(PageName.END, moduleIndex)
          return;
        } else {
          await this.$store.commit('case2', {moduleIndex, caseNr});
          await APIModule.updateProgress(PageName.CASE2, moduleIndex, caseNr)
        }
      }
      await this.$router.push({
        name: PageName.CASE_OVERVIEW,
        query: {
          module: moduleIndex
        }
      }).catch(()=>{});
    }
  }
}
</script>

<style scoped>
body {
  max-height: 100% !important;
}

.case {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  justify-content: end;
  align-items: center;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
  bottom: 16px;
  padding: 0 !important;
}

option {
  position: absolute;
  width: 35%;
  height: 25%;
  margin-top: 10%;
  pointer-events: none;
}

option.active {
  pointer-events: all;
  cursor: url('../assets/images/cursor/Pointinghand_cursor.png'), auto !important;
  z-index: 10;
}

option#option1 {
  margin-right: 7.5%;
  right: 0;
}

option#option2 {
  margin-left: 7.5%;
  left: 0;
}

@media (max-width: 575.98px) {
  .content {
    height: 100vh;
    bottom: auto;
  }
}

</style>
<style>
body:has(#app > .case){
  overflow-y: hidden!important;
}

@media (max-width: 575.98px) {
}
</style>
<style scoped lang="scss" src="../assets/scss/_cursor.scss"/>

