<template>
  <div id="progress-bar">
    <div class="progress">
      <div class="progress-video">

      </div>
      <div class="triangle"></div>
    </div>
    <div v-if="timestamps" id="flags">
      <Flag v-for="timestamp in timestamps" :key="timestamp" :timestamp="Number(timestamp)"
            :videoDuration="videoDuration"/>
    </div>
    <div class="opacity-bar"></div>
  </div>
</template>

<script>
import Flag from "@/components/case/Flag";

export default {
  name: "ProgressBar",
  components: {
    Flag
  },
  data() {
    return {
      videoDuration: 0
    }
  },
  mounted() {
    const player = document.getElementById('player');
    player.addEventListener('loadeddata', () => {
      this.videoDuration = player.duration;
    });
  },
  props: {
    timestamps: Array
  }
}
</script>
<style scoped lang="scss" src="./ProgressBar.scss"/>
