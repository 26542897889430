<template>
  <div class="login">
    <div aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center w-100 vh-50">
      <div class="blur" id="blur" hidden></div>
      <ToastComponent id="error" title="Foutmelding" :solid="true">
        <p class="mb-3">Je bevindt je in een afgeschermde omgeving, gebruik de QR-code om met je eigen device de
          training te starten.</p>
        <qr-code class="qr" :size="120" :text="qrLink"></qr-code>
      </ToastComponent>
    </div>
  </div>
</template>

<script>

import APIModule from "@/modules/APIModule";
import { PageName } from "@/utils/Constants";
import { Toast } from "bootstrap";
import ToastComponent from "@/components/case/Toast";

export default {
  name: "Login",
  props: ['loginCode'],
  components: {
    ToastComponent
  },
  data() {
    return {
      qrLink: window.location.href
    }
  },
  created() {
    console.log("created");
  },
  async mounted() {
    console.log("mounted");
    const loginCode = this.$route.query.loginCode;
    try {
      const userResponse = await APIModule.loginUser(loginCode);
      console.log("userResponse", userResponse);
      console.log("userResponse.status", userResponse.status);
      if (userResponse.status !== 400) {
        const progressResponse = await APIModule.getProgress(userResponse.data.user.id);
        console.log("progressResponse", progressResponse);
        console.log("progressResponse.data", progressResponse.data);
        if (progressResponse.data) {
          this.$store.commit('login', userResponse.data.user.id);
          this.$store.commit('walkthrough', progressResponse.data.progress);
          await this.$router.push({
            name: PageName.MODULE_OVERVIEW
          })
        }
      } else {
        await this.$router.push({
          name: PageName.REGISTER,
          params: {
            login: true
          }
        })
      }
    } catch (e) {
      console.log("error");
      console.log("e", e);
      document.getElementById('blur').hidden = false;
      this.showQrCode();
    }
  },
  methods: {
    showQrCode() {
      console.log("showQrCode");
      let toast = document.getElementById('error');
      toast = new Toast(toast, { autohide: false });
      toast.show();
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.blur {
  position: absolute !important;
  height: 100vh !important;
  width: 100% !important;
  background-color: #212121;
  opacity: 60%;
}

.qr {
  display: flex;
  justify-content: center !important;
  margin-top: 1rem !important;
  margin-bottom: 1.5rem !important;
}
</style>

