export function create(){
  function clickEffect(e){
    const d=document.getElementById('clickEffect');
    d.hidden=false;
    d.className="clickEffect";
    d.style.top=e.clientY+"px";d.style.left=e.clientX+"px";
    document.body.appendChild(d);
    d.addEventListener('animationend',function(){d.hidden=true}.bind(this));
  }
  document.getElementsByClassName('player')[0].addEventListener('click',clickEffect);
}
