<template>
  <div class="player">
    <video :poster="video.thumbnail" :class="clickable ? 'clickable' : ''" id="player" ref="player" playsinline>
      <source type="video/mp4" :src="video.src">
    </video>
    <img :src="icons.play" class="clickable" id="playButton" ref="playButton" alt="Play button">
    <div aria-live="polite" aria-atomic="true" class="position-absolute d-flex justify-content-center align-items-center w-100 vh-50">
      <div class="blur" id="blur" hidden></div>
      <ToastComponent id="error" title="Foutmelding" :solid="true">
        <p class="mb-3">Je bevindt je in een afgeschermde omgeving, gebruik de QR-code om met je eigen device de
          training te volgen.</p>
        <qr-code class="qr" :size="120" :text="qrLink"></qr-code>
      </ToastComponent>
    </div>
  </div>
</template>

<script>
import ToastComponent from "@/components/case/Toast";
import {Toast} from 'bootstrap';
import apiModule from "@/modules/APIModule";
import store from "@/store";

export default {
  name: 'Player',
  components: {
    ToastComponent
  },
  props: {
    video: Object,
    endVideo: Function,
    clickable: Boolean
  },
  data() {
    let params = new URLSearchParams(window.location.search);
    return {
      moduleIndex: parseInt(params.get('module')),
      qrLink: window.location.host + '/register',
      icons: {
        play: require('@/assets/images/icons/Play.svg'),
      },
    }
  },
  async mounted() {
    try {
      const response = await apiModule.newLoginCode(store.state.account.userId);
      if (response.data.success) {
        this.qrLink = window.location.host + '/login?loginCode=' + response.data.loginCode
      }
    } catch {
    }
    this.$refs.player.addEventListener('error', (e) => {
      e.preventDefault();
      document.getElementById('blur').hidden = false;
      this.showQrCode();
    }, true);
    this.$refs.player.addEventListener('click', (e) => {
      e.preventDefault();
      this.$refs.player.play();
    })
    this.$refs.playButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.$refs.playButton.hidden = true;
      this.$refs.player.play();
    })
    this.$refs.player.addEventListener('ended', (e) => {
      e.preventDefault();
      this.endVideo();
    })
    this.$refs.player.addEventListener('timeupdate', this.updateCurrentTime);
    if(this.moduleIndex === 1)
      this.$refs.player.addEventListener('timeupdate',  this.$parent.check);
    document.documentElement.style.setProperty('--videoProgress', "0");
  },
  beforeDestroy() {
    this.$refs.player.removeEventListener('timeupdate', this.updateCurrentTime);
  },
  methods: {
    updateCurrentTime(ev) {
      const v = ev.target;
      document.documentElement.style.setProperty('--videoProgress', `${(v.currentTime / v.duration) * 100}%`);
    },
    async showQrCode() {
      let toast = document.getElementById('error');
      toast = new Toast(toast, {autohide: false});
      toast.show();
    }
  },
  computed: {
    player() {
      if (this.$refs.player && this.$refs.player.readyState > 0) {
        return this.$refs.player;
      }
      // Return a stub to prevent errors
      return {
        currentTime: 0,
        duration: 0.1,
        readyState: 0,
        paused: true,
        ended: false,
      };
    },
  },
};
</script>

<style> /* Note that this isn't scoped - we declare variables on the document root */
:root {
  --videoProgress: 0%;
}
</style>
<!-- Style -->
<style scoped lang="scss" src="./Player.scss"/>
