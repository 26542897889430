<template>
  <div :class="this.module.disabled ? 'module-display row disabled m-0' : (this.module.active ? 'module-display row active m-0' : 'module-display row m-0')">
    <a v-if="!module.disabled" class="thumbnail" @click="goToPage(nr)">
      <div class="position-relative">
        <div :id="'module'+nr" class="img border-hover" alt="case thumbnail"/>
        <div class="number-holder">
          <div class="square">
            {{this.nr}}
          </div>
          <div class="triangle"></div>
        </div>
        <h2 class="title">{{ module.title }}</h2>
        <p class="status"><img :src="icons.click" alt="Click icon">{{ module.cases }} {{module.cases <= 1 ? 'casus' : 'casussen'}}</p>
        <div class="progress">
          <div class="progress-bar" role="progressbar" :style="'width: '+getProgressBarWidth(module.progress)"
               :aria-valuenow="module.progress" aria-valuemin="0" :aria-valuemax="module.cases+1"></div>
        </div>
      </div>
    </a>
    <a v-else class="thumbnail disabled">
      <div class="position-relative">
        <div :id="'module'+nr" class="img" alt="case thumbnail"/>

        <div class="number-holder">
          <div class="square">
            {{this.nr}}
          </div>
          <div class="triangle"></div>
        </div>
        <h2 class="title">{{ module.title }}</h2>
        <p class="status"><img :src="icons.click" alt="Click icon">{{ module.cases }} {{module.cases <= 1 ? 'casus' : 'casussen'}}</p>
        <div class="progress">
          <div class="progress-bar" role="progressbar" :style="'width: '+getProgressBarWidth(module.progress)"
               :aria-valuenow="module.progress" aria-valuemin="0" :aria-valuemax="module.cases+1"></div>
        </div>
      </div>
      <!-- <img src="../../assets/images/icons/lock.png" alt="lock"> -->
      <font-awesome-icon class="center-icon" :icon="['fa', 'lock']" />
    </a>
  </div>
</template>

<script>
export default {
  name: "ModuleDisplay",
  props: {
    nr: Number,
    module: Object,
    goToPage: Function
  },
  data() {
    return {
      icons: {
        click: require('@/assets/images/icons/Click.svg')
      }
    }
  },
  methods: {
    getProgressBarWidth(progress) {
      if(this.module.cases === 1)
        return progress === 0 ? '1%' : (progress === 1 ? '50%' : '100%');
      return progress === 0 ? '1%' : (progress === 1 ? '25%' : (progress === 2 ? '50%' : (progress === 3 ? '75%' : '100%')));
    }
  }
}
</script>

<!-- Style -->
<style lang="scss" src="./ModuleDisplay.scss"/>

