<template>
  <div class="signal">
    <div class="checkbox">
      <img :src="require('@/assets/images/icons/' + icon + '.svg')" alt="checkbox icon">
    </div>
    <div class="signal-content">
      <div class="signal-title">Signaal {{signal.title}}</div>
      <div class="signal-description">{{signal.description}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Signal",
  props: {
    signal: Object,
    icon: String,
    nr: Number
  }
}
</script>

<!-- Style -->
<style lang="scss" src="./Signal.scss"/>
