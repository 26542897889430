<template>
  <a @click="method(activeIndex-1)" class="previous-button"><img src="@/assets/images/buttons/round/secondary_round.png" width="48" class="flip" alt="previous button"></a>
</template>

<script>
export default {
  name: "PreviousButton",
  props: {
    activeIndex: Number,
    method: Function
  }
}
</script>

<style scoped>
.previous-button{
  pointer-events: all;
  z-index:5;
}
.flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  float: left;
}
</style>
