const state = {
  isLoggedIn: false,
  userId: null
};

const mutations = {
  logout(state) {
    state.isLoggedIn = false;
  },
  login(state, userId) {
    state.isLoggedIn = true;
    state.userId = userId;
  }
};

export default {
  state,
  mutations
}
