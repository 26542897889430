import {PageName as PAGES} from "@/utils/Constants";

const state = {
  redo: false,
  modules: [
    {
      page: "",
      caseNr: 1,
    },
    {
      page: "",
      caseNr: 1,
    }
  ]
};

const mutations = {
  introduction(state) {
    state.page = PAGES.INTRODUCTION;
  },
  walkthrough(state, progress) {
    progress.modules.forEach((module, index) => {
      state.modules[index].caseNr = module.caseNr;
      state.modules[index].page = PAGES.WALKTHROUGH;
    });
  },
  caseNr(state, progress) {
    state.modules[progress.moduleIndex].caseNr = progress.caseNr;
  },
  case(state, progress) {
    state.modules[progress.moduleIndex].page = PAGES.CASE1;
    state.modules[progress.moduleIndex].caseNr = progress.caseNr;
  },
  case2(state, progress) {
    state.modules[progress.moduleIndex].page = PAGES.CASE2;
    state.modules[progress.moduleIndex].caseNr = progress.caseNr;
  },
  report(state, progress) {
    state.modules[progress.moduleIndex].page = PAGES.REPORT;
    state.modules[progress.moduleIndex].caseNr = progress.caseNr;
  },
  reflection(state, progress) {
    state.modules[progress.moduleIndex].page = PAGES.REFLECTION;
    state.modules[progress.moduleIndex].caseNr = progress.caseNr;
  },
  end(state, moduleIndex) {
    state.modules[moduleIndex].page = PAGES.END;
    state.modules[moduleIndex].caseNr = 1;
  },
};

export default {
  state,
  mutations
}
