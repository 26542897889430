import Vue from 'vue'

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faBars, faInfo, faAddressBook, faQuestion, faEnvelope, faPhone, faVolumeMute, faVolumeUp, faCheckCircle, faLock, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faClock, faBars, faInfo, faAddressBook, faQuestion, faEnvelope,faPhone, faVolumeMute, faVolumeUp, faCheckCircle, faLock, faHome)
Vue.component('font-awesome-icon', FontAwesomeIcon)
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(BootstrapVue)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
