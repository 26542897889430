<template>
  <div class="report-content">
    <header class="report-header">
      <img :src="require('@/assets/images/logos/riec-logo.png')" alt="RIEC logo"/>
    </header>
    <div class="content">
      <div class="secondary-title">Samenvatting en aanleiding signaal</div>
      <div class="summary">
        {{ summary }}
      </div>
      <div class="secondary-title">Uitwerking signaal</div>
      <div v-if="signals" id="signals">
        <Signal v-for="(signal, index) in signals" :key="signal.id" :signal="signal" :nr="index+1" :icon="signal.valid ? 'Filled_checkbox' : 'Empty_checkbox'"/>
      </div>
    </div>
  </div>
</template>

<script>
import Signal from "@/components/report/Signal";

export default {
  name: "ReportContent",
  components: {
    Signal
  },
  props: {
    signals: Array,
    summary: String
  }
}
</script>

<!-- Style -->
<style lang="scss" src="./ReportContent.scss"/>
