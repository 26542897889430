<template>
  <div class="case-overview d-flex justify-content-center align-items-center w-100 mt-5">
    <img
      class="demo-bg"
      :src="thumbnails[background]"
      alt="background"
    >
    <CustomHeader :showMenu="true"/>
    <div class="content">
      <h1 class="title">
        MODULE {{ this.module.title }}
      </h1>
      <p class="description" v-html="this.moduleIndex === 0 ? `In deze module ga je aan de slag met herkennen, signaleren, vastleggen en doorzetten van verschillende criminele signalen. Je richt je hier op het snel en slagvaardig afpakken van crimineel verkregen vermogen.`
            : `Op deze pagina vind je de trainingen gelinkt aan de module ‘Betekenisvol Afpakken’. In deze module volg je het verhaal van Dennis V.
Aan de hand van het verhaal worden er verschillende vragen aan je gesteld. Voltooi de training door alle vragen juist te beantwoorden.
Bekijk eerst de tutorial om aan de slag te gaan.`">
      </p>
      <div v-if="this.module.videos" class="case-holder my-4" :id="'module'">
        <CaseDisplay :key="this.module.tutorial.title" :nr="0"
                     :total="getNumberOfCases" :video="this.module.tutorial"
                     :goToPage="() => goToWalkthrough()"/>
        <CaseDisplay v-for="(video, index) in this.module.videos" :key="video.title" :nr="index+1"
                     :total="getNumberOfCases" :video="video"
                     :goToPage="()=> goToPage(video.caseNr)"/>
      </div>
    </div>
    <Popup>
      <div class="button-container">
        <CustomButton class="button" @click.native="goToCase()">
          <img class="icon" :src="icons.replay" alt="Click icon"/><span
          class="button-text"> Casus opnieuw doorlopen</span>
        </CustomButton>
        <SecondaryButton v-if="this.moduleIndex===0" class="button" @click.native="goToReflection()">
          <span class="button-text">Reflectie video bekijken</span>
        </SecondaryButton>
      </div>
    </Popup>
    <div class="footer">
      <PreviousButton :method="goToModuleOverview"/>
    </div>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader";
import CaseDisplay from "@/components/case/CaseDisplay";
import store from "@/store";
import {PageName} from "@/utils/Constants";
import APIModule from "@/modules/APIModule";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import CustomButton from "@/components/buttons/CustomButton";
import Popup from "@/components/Popup";
import PreviousButton from "@/components/buttons/PreviousButton";

export default {
  name: "CaseOverview",
  components: {
    Popup,
    CustomHeader,
    CaseDisplay,
    CustomButton,
    SecondaryButton,
    PreviousButton
  },
  props: {
    moduleIndex: Number,
  },
  data() {
    const numberOfCases = this.moduleIndex === 0 ? 3 : 1;
    return {
      thumbnails: [
        require('@/assets/images/thumbnails/Jeugdoverlast.png'),
        require('@/assets/images/thumbnails/Verkeersoverlast.png'),
        require('@/assets/images/thumbnails/Deurwaarder.png'),
        require('@/assets/images/thumbnails/betekenisvol_afpakken.jpg')
      ],
      background: this.moduleIndex * 3 + store.state.progress.modules[this.moduleIndex].caseNr - 1,
      icons: {
        replay: require('@/assets/images/icons/Replay.svg')
      },
      numberOfCases,
      module:
        {
          title: this.moduleIndex === 0 ? "ONGEBRUIKELIJK BEZIT" : "BETEKENISVOL AFPAKKEN",
          cases: this.moduleIndex === 0 ? 3 : 1,
          currentCase: store.state.progress.modules[this.moduleIndex].caseNr,
          tutorial: {
            title: 'TUTORIAL',
            thumbnail: require('@/assets/images/thumbnails/walkthrough.png'),
            active: false,
            disabled: false,
            description: this.moduleIndex === 0 ? `In deze tutorial vind je een werkinstructie die je verdere uitleg en instructies geeft. Je
krijgt hier de tools om je verder te navigeren in de online trainingsomgeving en aan de
slag te gaan met de module ‘Ongebruikelijk Bezit’.` : `In deze tutorial vind je een werkinstructie die je verdere uitleg en instructies geeft. Je
krijgt hier de tools om je verder te navigeren in de online trainingsomgeving en aan de
slag te gaan met de module ‘Betekenisvol Afpakken’.`
          },
          videos: []
        },
      toastTitle: 'JE KUNT VANAF NU TAGGEN!',
      popUpCaseNr: 1
    }
  },
  async mounted() {
    store.state.progress.redo = false;
    const response = await APIModule.getProgress(store.state.account.userId);
    if (!response.data) {
      await this.$router.push({
        name: PageName.REGISTER
      });
    }

    const caseNr = response.data.progress.modules[this.moduleIndex].caseNr;
    const page = response.data.progress.modules[this.moduleIndex].page;
    this.module.currentCase = caseNr;
    this.module.page = page;
    if (page === PageName.CASE1 && store.state.progress.caseNr > caseNr) {
      this.module.currentCase = store.state.progress.caseNr;
      await APIModule.updateProgress(PageName.CASE1, this.moduleIndex, this.module.currentCase);
    }
    this.module.tutorial.active = page === PageName.WALKTHROUGH;
    this.background = this.moduleIndex * 3 + this.module.currentCase - 1;

    if (this.moduleIndex === 0) {
      this.module.videos = [
        {
          caseNr: 1,
          title: 'JEUGDOVERLAST',
          thumbnail: this.thumbnails[0],
          progress: this.module.currentCase > 1 ? 3 : this.checkProgress(this.module.page),
          active: this.module.page !== PageName.WALKTHROUGH && this.module.currentCase === 1,
          disabled: this.module.page === PageName.WALKTHROUGH,
          duration: 20,
          parts: 3,
          description: `In de training ‘Jeugdoverlast’ volg je Amber en Marlijn tijdens hun dienst. Wanneer de
meldkamer met een nieuwe melding komt gaan ze op pad om een verdachte situatie
in een parkeergarage te analyseren.`
        },
        {
          caseNr: 2,
          title: 'VERKEERSCONTROLE',
          thumbnail: this.thumbnails[1],
          progress: this.module.currentCase === 2 ? this.checkProgress(this.module.page) : (this.module.currentCase < 2 ? 0 : 3),
          active: this.module.currentCase === 2,
          disabled: this.module.currentCase < 2,
          duration: 20,
          parts: 3,
          description: `In de training ‘Verkeerscontrole’ volg je een politieagent op motor die een opvallende
Mercedes met twee personen signaleert. Weet jij adequaat te handelen en de juiste
signalen vast te leggen?`
        },
        {
          caseNr: 3,
          title: 'DEURWAARDER',
          thumbnail: this.thumbnails[2],
          progress: this.module.currentCase === 3 ? this.checkProgress(this.module.page) : 0,
          active: this.module.currentCase === 3,
          disabled: this.module.currentCase < 3,
          duration: 20,
          parts: 3,
          description: `In deze training ontvangt deurwaarder Joop een telefoontje van afdeling Invordering.
We gaan langs bij de Heer Goossens: directeur van een garagebedrijf en eigenaar van
een opvallende auto. Lukt het jou om de juiste signalen te taggen?`
        },
      ];
    } else if (this.moduleIndex === 1) {
      this.module.videos = [
        {
          caseNr: 1,
          title: 'DENNIS V.',
          thumbnail: this.thumbnails[3],
          progress: this.checkProgress(this.module.page),
          active: this.module.page !== PageName.WALKTHROUGH && this.module.currentCase === 1,
          disabled: this.module.page === PageName.WALKTHROUGH,
          duration: 20,
          parts: 1,
          description: `In deze training volg je het verhaal van Dennis V., XTC-producent en befaamd
crimineel. Wanneer de politie hem op het spoor is heeft dit een groot effect op zijn
gezin. Lukt het jou om de juiste keuzes te maken omtrent het begrip ‘Betekenisvol
Afpakken’?`
        },
      ];
    }
  },
  methods: {
    checkProgress(page) {
      if (this.moduleIndex === 0)
        return page === PageName.CASE1 ? 0 : (page === PageName.REPORT ? 1 : (page === PageName.END ? 3 : 2));
      return page === PageName.CASE2 ? 0 : (page === PageName.END ? 3 : 0);
    },
    async goToPage(caseNr) {
      // Return if case is inactive
      if(!this.module.videos[caseNr - 1].active)
        return

      const moduleIndex = this.moduleIndex;
      const page = this.module.videos[caseNr - 1].progress === 0 ?
        (this.moduleIndex === 0 ? 'case' : 'case2') : (this.module.videos[caseNr - 1].progress === 1 ?
          'report' : (this.module.videos[caseNr - 1].progress === 2 ?
            'reflection' : 'done'));
      if (page === 'done') {
        this.showPopup(caseNr);
        return;
      }
      this.$store.commit(page, {moduleIndex, caseNr});
      await this.$router.push({
        name: store.state.progress.modules[moduleIndex].page,
        query: {
          module: moduleIndex
        }
      });
      window.dataLayer?.push({event: 'module_' + (moduleIndex + 1) + '_video_' + caseNr});
    },
    async goToWalkthrough() {
      const moduleIndex = this.moduleIndex;
      await this.$router.push({
        name: PageName.WALKTHROUGH,
        query: {
          module: moduleIndex
        }
      });
    },
    async goToCase() {
      const moduleIndex = this.moduleIndex;
      const caseNr = this.popUpCaseNr;
      store.state.progress.redo = true;
      this.$store.commit('caseNr', {moduleIndex, caseNr});
      let pageName = PageName.CASE1;
      if (moduleIndex === 1)
        pageName = PageName.CASE2;
      await this.$router.push({
        name: pageName,
        query: {
          module: moduleIndex
        }
      });
      window.dataLayer?.push({event: 'start_again'});
    },
    async goToReflection() {
      const moduleIndex = this.moduleIndex;
      const caseNr = this.popUpCaseNr;
      store.state.progress.redo = true;
      this.$store.commit('caseNr', {moduleIndex, caseNr});
      await this.$router.push({
        name: PageName.REFLECTION,
        query: {
          module: moduleIndex
        }
      });
      window.dataLayer?.push({event: 'watch_reflection'});
    },
    async goToModuleOverview() {
      await this.$router.push({
        name: PageName.MODULE_OVERVIEW,
      });
    },
    showPopup(caseNr) {
      this.popUpCaseNr = caseNr;
      let toast = document.getElementById('popup');
      toast.classList.add('show');
    }
  },
  computed: {
    getNumberOfCases() {
      return this.numberOfCases ? this.numberOfCases : 0;
    }
  }
}
</script>

<style scoped lang="scss">
.demo-bg {
  position: fixed;
}

.content {
  align-items: center;
  display: flex;
  flex-flow: column;
  max-height: 100%;
}

.title {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: white;
}

.description {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: white;
  width: 700px;
}

.case-holder {
  display: flex;
  flex-wrap: wrap;
  min-width: 816px;
  max-width: 816px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 48px;
  z-index: 10;
  pointer-events: none;
}

.popup-body {
  padding: 32px !important;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center !important;
  gap: 1rem;
}

.button {
  width: 285px;

  .icon {
    width: 15px;
    filter: brightness(0%);
    margin-right: 0.5em;
  }

  .button-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 575.98px) {
  .case-overview {
    height: auto;
    max-height: none;
  }

  .content {
    margin-top: 3em;
  }

  .title {
    font-size: 30px;
    line-height: 34px;
  }

  .description {
    width: 100%;
  }

  .case-holder {
    margin-top: 1em;
    min-width: 90%;
    max-width: 90%;
    margin-bottom: 4em !important;
  }

  .footer {
    padding: 28px;
  }
}

@media (min-height: 300px) and (max-height: 420px) {
  .case-holder {
    margin-top: 0;
    max-width: 95%;
    min-width: 95%;
  }

  .title {
    margin-top: 0.6em;
    font-size: 30px;
    line-height: 34px;
  }

  .description {
    width: 100%;
  }

  .content {
    padding-bottom: 0;
  }

  .footer {
    padding-bottom: 30px;
    padding-right: 40px;
  }
}
</style>
<style>
body:has(#app > .case-overview) {
  overflow-y: auto !important;
}

@media (max-width: 575.98px) {
}
</style>
