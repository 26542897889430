import configRaw from "@/assets/data/config.json";

//Map JSON to type
const configData = (configRaw);

/**
 * Module used for config related things
 */
export default class ConfigModule {

    /**
     * Get page data from config by page name
     * @param name a PageName enum value, for the requested page (must exist in config)
     */
    static getPageByName(name) {
        const page = configData.pages.find(item => item.name === name);
        if(!page) {
            throw new Error("requested page doesn't exist!");
        }
        return page;
    }

    /**
     * Get complete config data, currently only used for settings
     * @returns IConfig the config object
     */
    static get() {
        return configData;
    }
}
