<template>
  <div class="walkthrough">

    <img v-for="i in [activeIndex]" :key="i" class="demo-bg" id="bg" :src="backgrounds[activeIndex]" alt="activeIndex">
    <CustomHeader />
    <div>
      <transition-group class="slider" :name="back ? 'slideback' : 'slide'" mode="out-in" tag="div">
        <WalkthroughItem v-for="i in [activeIndex]" :key="i" v-bind="{
          icon: currentWalkThrough.icon,
          title: currentWalkThrough.title,
          description: currentWalkThrough.description,
          lastItem: (activeIndex === items.length - 1),
          ...(currentWalkThrough.buttonText ? { buttonText: currentWalkThrough.buttonText } : {}),
          onFinished: goToOverview
        }" />
      </transition-group>
      <CustomFooter :activeIndex="activeIndex" :amount="items.length" :lastItem="activeIndex === items.length - 1"
        :goToPosition="goToPosition" />
    </div>
  </div>
</template>

<script>
import { PageName } from "@/utils/Constants";
import ConfigModule from "@/modules/ConfigModule";
import CustomFooter from "@/components/walkthrough/CustomFooter";
import WalkthroughItem from "@/components/walkthrough/WalkthroughItem";
import CustomHeader from "@/components/CustomHeader";
import APIModule from "@/modules/APIModule";
import store from "@/store";

export default {
  name: "Walkthrough",
  components: {
    CustomHeader,
    WalkthroughItem,
    CustomFooter,
  },
  props: {
    moduleIndex: Number,
  },
  data() {
    const data = ConfigModule.getPageByName(PageName.WALKTHROUGH);
    const itemData = Array.from(Array.from(data.modules)[this.moduleIndex].content);
    return {
      items: itemData,
      activeIndex: 0,
      back: false,
      backgrounds: [
        require('@/assets/images/backgrounds/NH Samen Veilig - Case - Jeugdoverlast - V3.00_00_41_20.Still007.png'),
        require('@/assets/images/backgrounds/NH Samen Veilig - Case - Deurwaarder - V3.00_02_03_20.Still007.png'),
        require('@/assets/images/backgrounds/NH Samen Veilig - Case - Verkeersoverlast - V3.00_01_28_24.Still009.png'),
        require('@/assets/images/backgrounds/NH Samen Veilig - Case - Verkeersoverlast - V3.00_00_31_20.Still006.png')
      ]
    };
  },
  methods: {
    async goToPosition(index) {
      document.getElementById('bg').classList.toggle('fadebg')
      if (index >= this.items.length) {
        await this.goToOverview();
      }
      this.back = this.activeIndex > index;
      this.activeIndex = index;
      if (this.back)
        window.dataLayer?.push({ event: 'onboarding_next', });
      else
        window.dataLayer?.push({ event: 'onboarding_previous', });
    },
    beforeLeave(el) {
      const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)
      el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`
      el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`
      el.style.width = width
      el.style.height = height
    },
    async goToOverview() {
      const response = await APIModule.getProgress(store.state.account.userId);
      const page = response.data.progress.modules[this.moduleIndex].page;
      const moduleIndex = this.moduleIndex;

      if (page === PageName.WALKTHROUGH) {
        const caseNr = 1;
        if (this.moduleIndex === 0) {
          this.$store.commit('case', { moduleIndex, caseNr })
          await APIModule.updateProgress(PageName.CASE1, this.moduleIndex, 1);
        }
        else {
          this.$store.commit('case2', { moduleIndex, caseNr })
          await APIModule.updateProgress(PageName.CASE2, this.moduleIndex, 1);
        }
      }
      await this.$router.push({
        name: PageName.CASE_OVERVIEW,
        query: {
          module: moduleIndex
        }
      });
      window.dataLayer?.push({ event: 'onboarding_start', });
    }
  },
  computed: {
    currentWalkThrough: function () {
      return this.items[this.activeIndex];
    }
  }
}
</script>

<style scoped>
.walkthrough {
  max-height: 100vh;
}

.demo-bg {
  transition: all 0.5s linear;
  animation: fade 1s linear;
}

.fade {
  animation: fade 1s linear;
}

@keyframes fade {

  0%{
    opacity: 0
  }

  100% {
    opacity: 0.24
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: 1.5s;
  opacity: 1;
}

.slide-enter {
  transform: translate(100%, -50%) !important
}

.slide-leave-to {
  transform: translate(-100%, -50%) !important
}

.slideback-leave-active,
.slideback-enter-active {
  transition: 1.5s;
  opacity: 1;
}

.slideback-enter {
  transform: translate(-100%, -50%) !important;
}

.slideback-leave-to {
  transform: translate(100%, -50%) !important;
}

.slide-enter,
.slide-leave-active {
  opacity: 0
}

.slideback-enter,
.slideback-leave-active {
  opacity: 0
}

.fade-in-leave-active,
.fade-in-enter-active {
  transition: 1.5s;
  opacity: 0.25;
}

.fade-in-enter {
  transform: translate(100%, 0%) !important
}

.fade-in-leave-to {
  transform: translate(-100%, 0%) !important
}

.fade-in-enter,
.fade-leave-active {
  opacity: 0
}

.fade-out-leave-active,
.fade-out-enter-active {
  transition: 1.5s;
  opacity: 0.25;
}

.fade-out-enter {
  transform: translate(-100%, 0%) !important;
}

.fade-out-leave-to {
  transform: translate(100%, 0%) !important;
}

.fade-out-enter,
.fade-out-leave-active {
  opacity: 0
}

.slider {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
}

.slider .walkthrough-item {
  position: absolute;
  top: 42%;
  transform: translateY(-50%)
}

@media (max-width: 575.98px) {
  .walkthrough {
    height: 100vh;
    max-height: none;
  }
}
</style>

<style>
body:has(#app > .walkthrough) {
  overflow-y: hidden;
}

@media (max-width: 575.98px) {}
</style>
