import { render, staticRenderFns } from "./ModuleDisplay.vue?vue&type=template&id=696a68fa&"
import script from "./ModuleDisplay.vue?vue&type=script&lang=js&"
export * from "./ModuleDisplay.vue?vue&type=script&lang=js&"
import style0 from "./ModuleDisplay.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports