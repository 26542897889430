<template>
  <div class="col-sm card-item">
    <div v-if="icon" class="icon">
      <font-awesome-icon :icon="['fa', icon]"/>
    </div>
    <div class="card-body text-center">
        <div>
          <h1 class="card-title">{{ title.toUpperCase() }}</h1>
          <p v-if="description" class="card-text">{{ description }}</p>
        </div>
        <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "ContactBox",
  components: {
  },
  props: {
    icon: String,
    title: String,
    description: String,
  }
}
</script>

<!-- Style -->
<style lang="scss" src="./ContactBox.scss"/>
