<!-- Template -->
<template>
    <div class="walkthrough-item" ref="root">
      <img class="walkthrough-icon" :src="require('@/assets/' + icon + '')" alt="walkthrough visual"/>

      <div class="walkthrough-content">
        <div class="walkthrough-title">{{ title }}</div>
        <div v-if="description" class="walkthrough-description" v-html="description"></div>
        <CustomButton class="next-button" v-if="lastItem" :method="onFinished">{{String(buttonText)}}</CustomButton>
      </div>
    </div>
</template>


<!-- Script -->
<script lang="js">
import CustomButton from "@/components/buttons/CustomButton.vue";

export default ({
  name: "WalkthroughItem",
  components: {CustomButton},
  props: {
    icon: {type: String, required: true},
    title: {type: String, required: true},
    description: {type: String, required: true},
    lastItem : {type: Boolean, required: true},
    buttonText: {type: String, required: false},
    onFinished: Function
  }
});
</script>


<!-- Style -->
<style lang="scss" src="./WalkthroughItem.scss"/>

