<template>
  <div class="module-overview d-flex justify-content-center align-items-center w-100 mt-5">
    <img
      class="demo-bg"
      :src="this.modules[0].thumbnail"
      alt="background"
    >
    <CustomHeader :showMenu="true"/>
    <div class="content">
      <h1 class="title">
        TRAINING MODULES
      </h1>
      <p class="description">
        De online trainingen die je gaat volgen zijn verdeeld in
        verschillende modules.
        Op deze pagina vind je de modules die voor jou beschikbaar zijn. 
      </p>
      <div class="case-holder" :id="'modules'">
        <ModuleDisplay v-for="(module, moduleIndex) in modules"
                       :key="module.title" :nr="moduleIndex+1"
                       :module="module" :goToPage="() => goToCaseOverview(moduleIndex)"/>
      </div>
    </div>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader";
import ModuleDisplay from "@/components/case/ModuleDisplay";
import store from "@/store";
import {PageName} from "@/utils/Constants";
import APIModule from "@/modules/APIModule";
import CustomButton from "@/components/buttons/CustomButton";

export default {
  name: "ModuleOverview",
  components: {
    CustomHeader,
    ModuleDisplay,
    CustomButton,
  },
  data() {
    return {
      modules: [
        {
          title: "ONGEBRUIKELIJK BEZIT",
          thumbnail: require('@/assets/images/thumbnails/Verkeersoverlast.png'),
          currentCase: store.state.progress.modules[0].caseNr,
          disabled: false,
          cases: 3,
          progress: 0
        },
        {
          title: "BETEKENISVOL AFPAKKEN",
          thumbnail: require('@/assets/images/thumbnails/betekenisvol_afpakken.jpg'),
          currentCase: store.state.progress.modules[1].caseNr,
          disabled: false,
          cases: 1,
          progress: 0
        }
      ],
    }
  },
  async mounted() {
    const response = await APIModule.getProgress(store.state.account.userId);
    if (!response.data) {
      await this.$router.push({
        name: PageName.REGISTER
      });
    }

    this.modules.forEach(async (module, moduleIndex, array) => {
      const caseNr = response.data.progress.modules[moduleIndex].caseNr;
      const page = response.data.progress.modules[moduleIndex].page;
      array[moduleIndex].currentCase = caseNr;
      array[moduleIndex].page = page;
      if (page === PageName.CASE1 && store.state.progress.caseNr > caseNr) {
        array[moduleIndex].currentCase = store.state.progress.caseNr;
        await APIModule.updateProgress(PageName.CASE1, moduleIndex, array[moduleIndex].currentCase);
      }
    });

    this.modules[0].progress = this.checkProgress(0, this.modules[0].currentCase, this.modules[0].page, this.modules[0].cases);
    this.modules[1].progress = this.checkProgress(1, this.modules[1].currentCase, this.modules[1].page, this.modules[1].cases);
  },
  methods: {
    checkProgress(moduleIndex, caseNr, page, numberOfCases) {
      if (page === PageName.WALKTHROUGH)
        return 0;
      return page === PageName.END && caseNr >= numberOfCases ? numberOfCases + 1 : caseNr;
    },
    async goToCaseOverview(moduleIndex) {
      await this.$router.push({
        name: PageName.CASE_OVERVIEW,
        query: {
          module: moduleIndex
        }
      });
      window.dataLayer?.push({event: 'module_' + (moduleIndex + 1)});
    },
  }
}
</script>

<style scoped>
.module-overview {
  max-height: 100vh;
}

.content {
  align-items: center;
  display: flex;
  flex-flow: column;
  max-height: 100%;
}

.title {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: white;
}

.description {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: white;
  width: 690px;
  margin-bottom: 72px;
}

.case-holder {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  width: 100%;
  justify-content: center;
}

@media (max-width: 575.98px) {
  .module-overview {
    height: auto;
    max-height: none;
  }

  .content {
    margin-top: 3em;
  }

  .title {
    font-size: 30px;
    line-height: 34px;
  }

  .description {
    width: 100%;
    margin-bottom: 36px;
  }

  .case-holder {
    margin-top: 1em;
  }
}

@media (min-height: 300px) and (max-height: 420px) {
  .module-overview {
    margin-top: 8rem !important;
  }

  .case-holder {
    margin-top: 0;
    width: 700px;
    margin-bottom: 32px;
  }

  .title {
    margin-top: 0.6em;
    font-size: 30px;
    line-height: 34px;
  }

  .description {
    width: 80%;
    margin-bottom: 36px;
  }

  .content {
    padding-bottom: 0;
    overflow-x: hidden;
  }
}
</style>
<style>
body:has(#app > .module-overview) {
  overflow-y: hidden !important;
}

@media (max-width: 575.98px) {
  body:has(#app > .module-overview) {
    overflow-y: auto !important;;
  }
}

@media (min-height: 300px) and (max-height: 420px) {
  body:has(#app > .module-overview) {
    overflow-y: auto !important;
  }
}
</style>

