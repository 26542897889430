<template>
  <div class="contact d-flex justify-content-center align-items-center vh-100">
    <img
      class="demo-bg w-100"
      :src="bg"
      alt="background"
    >
    <CustomHeader/>
    <div class="content">
      <h1 class="title">
        {{ content.title }}
      </h1>
      <p class="description">
        Heb je vragen, of wil je graag meer informatie over Schakel Scherp, <span class="font-weight-bold">FIT</span> of <a
        href="https://www.nh-sv.nl/" target="_blank">Noord-Holland Samen Veilig</a>? Via de onderstaande links neem je eenvoudig contact met ons op.
      </p>
      <div class="boxes row">
        <ContactBox v-for="({ title, linkText, email }, index) in content.emailDetails" :key="index" :title="title">
          <SecondaryButton :method="() => toMail(email)" class="w-100 align-self-center">
            {{ linkText }}
          </SecondaryButton>
        </ContactBox>
      </div>
    </div>
    <div class="footer">
      <PreviousButton :method="back"/>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/buttons/CustomButton";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import CustomHeader from "@/components/CustomHeader";
import ConfigModule from "@/modules/ConfigModule";
import {PageName} from "@/utils/Constants";
import ContactBox from "@/components/contact/ContactBox";
import PreviousButton from "@/components/buttons/PreviousButton";

export default {
  name: "Contact",
  components: {
    ContactBox,
    CustomHeader,
    CustomButton,
    SecondaryButton,
    PreviousButton
  },
  data() {
    const content = ConfigModule.getPageByName(PageName.CONTACT);
    return {
      content,
      bg: require('@/assets/images/backgrounds/bg1.png')
    }
  },
  methods: {
    toMail(email) {
      if (email === 'financial-intelligence-team.noord-holland@politie.nl')
        window.dataLayer?.push({event: 'contact_send_mail_modules',});
      else
        window.dataLayer?.push({event: 'contact_send_mail_videos',});
      window.location = 'mailto:' + email + '?subject=Vraag FIT videotraining&body=';
    },
    back(){
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  max-height: 100% !important;
}

.content {
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-bottom: auto;
  margin-top: 50px;

  .info-icon {
    width: 120px;
    height: 120px;
  }

  .title {
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    color: white;
    margin: 0;
  }
}

.stroke1 {
  background: white;
  margin-top: 16px;
  height: 16px;
  max-width: 452px;
}

.stroke2 {
  background: white;
  margin-top: 16px;
  max-width: 452px;
}

.description {
  max-width: 792px;
  font-size: 20px;
  line-height: 28px;
  color: white;
  margin-top: 24px;
  margin-bottom: 0;
}

.boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-top: 48px;
}

a {
  color: #F3FF00 !important;
  &:hover{
    cursor: pointer;
  }
}

@media (max-width: 575.98px) {
  .contact {
    height: auto !important;
  }
  .content {
    height: auto !important;

    .title {
      font-size: 37.5px;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 48px;
  z-index: 10;
}

@media (min-height: 300px) and (max-height: 420px) {
  .contact {
    height: auto !important;
  }
  .content {
    height: auto !important;

    .title {
      font-size: 37.5px;
    }
  }
  .info-icon {
    height: 80px !important;
  }
}
</style>
<style>
body:has(#app > .contact){
  overflow-y: hidden!important;;
}

@media (max-width: 575.98px) {
  body:has(#app > .contact){
    overflow-y: auto!important;;
  }
}

@media (min-height: 300px) and (max-height: 420px) {
  body:has(#app > .contact) {
    overflow-y: auto !important;
  }
}
</style>

