import axios from "axios";
import dotenv from 'dotenv';
import store from "@/store";

dotenv.config();

/**
 * Module for API calls
 */
class APIModule {
  /**
   * Create a user based on provided email - works for creation only!
   * @param email user e-mailaddress
   */
  async createEmailUser(email) {
    return await this.request("POST", "api/createUser", {email});
  }

  /**
   * Login user with loginCode
   * @param loginCode
   */
  async loginUser(loginCode) {
    return await this.request("POST", "api/login", {loginCode});
  }

  /**
   * Get new login code based on provided userId
   * @param userId
   */
  async newLoginCode(userId) {
    return await this.request("POST", `api/newLoginCode/${userId}`, {});
  }

  /**
   * Get user progress
   * @param userId
   */
  async getProgress(userId) {
    return await this.request("GET", `api/progress/${userId}`, {});
  }

  /**
   * Update user progress
   * @param page
   * @param moduleIndex
   * @param caseNr
   */
  async updateProgress(page, moduleIndex,  caseNr = '') {
    return await this.request("PUT", `api/progress/${store.state.account.userId}/modules/${moduleIndex}`, {page, caseNr});
  }

  /**
   * Get user cases
   */
  async getUserCase(userId, caseNr) {
    return await this.request("GET", `api/case/${caseNr}/${userId}`, {});
  }

  /**
   * Get user cases
   */
  async addSignalTimestamp(userId, caseId, timestamp) {
    return await this.request("POST", `api/addSignalTimestamp?userId=${userId}&caseId=${caseId}`, {timestamp});
  }

  /**
   * Get timestamps of user case
   */
  async getTimeStamps(userCaseId){
    return await this.request("GET", `api/cases/${userCaseId}/timestamps`, {});
  }

  /**
   * Internal request function, handles things like retries & token
   * @param method the request method (e.g. POST or GET)
   * @param path the path to required endpoint (is appended to base URL set in .env)
   * @param data request data, will be automatically converted to query for GET or JSON body for POST-like requests
   * @returns response returns the response body
   */
  async request(method, path, data) {
    const normalizedMethod = method.toLowerCase();
    let result;

    //Try to do call
    try {
      result = await axios(`${process.env.VUE_APP_API_URL}/${path}`, {
        method: normalizedMethod,

        //Set data
        ...(normalizedMethod === "get" && data ? {params: data} : {}),
        ...(normalizedMethod !== "get" && data ? {data: data} : {}),
      });
    } catch (e) {
      result = e.response ? e.response.data : null;
      result.status = e.response ? e.response.status : null;
      return result
    }

    //Return result
    return result ? result.data : null;
  }
}

const apiModule = new APIModule();
export default apiModule;
