//All available page names, mainly used for navigation or config purposes
export const PageName = {
  LAUNCH: "Launch",
  LOGIN: "Login",
  REGISTER: "Register",
  WELCOME: "Welcome",
  INTRODUCTION: "Introduction",
  WALKTHROUGH: "Walkthrough",
  CASE_OVERVIEW: "CaseOverview",
  MODULE_OVERVIEW: "ModuleOverview",
  CASE1: "Case1",
  CASE2: "Case2",
  REPORT: "Report",
  REFLECTION: "Reflection",
  CONTACT: "Contact",
  INFO: "Info",
  END: "End"
}

export const ErrorMessage = {
  "User already exists with this email": "Gebruiker bestaat al met deze email",
  "Email is required": "Email is verplicht",
  "Email domain doesn't have right domain": "Email domein heeft geen toegang",
}
