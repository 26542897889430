<template>
  <div class="flag" :id="'flag'+timestamp" :style="`margin-left: calc(${(this.timestamp / this.videoDuration) * 100}% - 16px);`">
    <img src="@/assets/images/icons/Flag.png" alt="flag">
  </div>
</template>

<script>
export default {
  name: "Flag",
  props: {
    timestamp: Number,
    videoDuration: Number
  }
}
</script>

<!-- Style -->
<style lang="scss" src="./Flag.scss"/>
